export const DEFAULT_PLEDGE_EMAIL_BODY = `
    <p>Dear {donor_first_name}, </p>
    <p>Thank you for pledging {amount} to support {organization_name}. We appreciate your generosity and commitment.</p>
    <p>Please complete your donation, using the secure link below:</p>
    <p>{donation_link}</p>
    <p>We look forward to your continued support, and if you need any assistance, feel free to reach out.</p><p>Thank you,</p><p>{organization_name}</p>
`;
export const DEFAULT_PLEDGE_EMAIL_SUBJECT = `Your donation pledge to {organization_name}`;

export const DEFAULT_EMAIL_BODY = `
    <p>Dear {donor_first_name},</p>
    <p><br></p>
    <p>This is a receipt for your generous donation to {organization_name}.</p>
    <p><br></p>
    <p>{donation_details}</p>
    <p><br></p>
    <p>Thank you,</p>
    <p><br></p>
    <p>{organization_name}</p>
`;

export const DEFAULT_EMAIL_SUBJECT = `Thanks for donating to {organization_name}`;

export const PAYMENT_METHOD_TYPE = {
    CARD: 'CARD',
    GOOGLE_PAY: 'GOOGLE_PAY',
    APPLE_PAY: 'APPLE_PAY',
    ACH: 'ACH'
}

export const PREFERRED_PAYMENT_METHOD_MAPPER = {
    "check":"Check",
    "cash":"Cash",
    "credit card":"Credit Card",
    "zelle":"Zelle",
    "paypal":"Paypal",
    "bank transfer":"Bank Transfer",
    "other": "Other"
}