import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "./devices.scss";
import AddDeviceModal from './add-device-dialog';
import getFormattedDateTime from '../../../../utils/date-time-format';
import axios from 'axios';
import { DELETE_DEVICE, GET_REGISTERED_DEVICES, SEND_PUSHER_EVENT } from '../../../../constants/endpoints';
import pusher from "../../../../pusher/pusher-connection";
import { getUserInfo } from '../../../../utils/user-info';
import { ENVIRONMENT_CONSTANT } from '../../../../constants/env.constant';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import Loader from "../../../../shared/loader/loader";

const Devices = React.forwardRef((props, ref) => {

    //initializing children refs
    const childRef = useRef(null);
    const confirmationDialogRef = useRef(null);

    const masjidID = getUserInfo()?.masjid_id;

    const [devices, setDevices] = useState([]);
    const [devicesCopy, setDevicesCopy] = useState([]);

    const devicesRef = useRef();
    const activeDevicesRef = useRef();
    devicesRef.current = devices;

    const [dataLoader, setDataLoader] = useState(false);

    const getRegisteredDevices = (deviceID) => {
        setDataLoader(true);
        axios.get(GET_REGISTERED_DEVICES)
            .then((res) => {
                setDataLoader(false);
                if (res?.data?.status) {
                    
                    // setDevicesCopy(res?.data?.response);
                    if(pusher.connection.state === 'connected' && !deviceID){
                        const updatedData = [...res?.data?.response];
                        const presenceChannel = pusher.channels.channels['presence-'+masjidID];
                        const members = presenceChannel.members.members;
                        for (var key in updatedData) {
                            if(members[updatedData[key]?.id]){
                                updatedData[key].device_status = 'Online';
                            }else{
                                updatedData[key].device_status = 'Checking';
                            }
                        }

                        setDevices(updatedData);
                    }else{
                        if(deviceID){
                            const index = res?.data?.response.findIndex(it => it.id === deviceID);
                            if(index >= 0){
                                res.data.response[index].device_status = 'Offline';
                            }
                        }
                        setDevices(res?.data?.response);
                    }
                    
                }
            }).catch((error) => {
                setDataLoader(false);
            })
    }

    const deleteDevice = (device) => {
        const formData = {
            device_code: device?.device_code
        }
        axios.delete((DELETE_DEVICE + device?.id), { data: formData })
            .then((res) => {
                if (res?.data?.status) {
                    getRegisteredDevices();
                }
            }).catch((error) => {

            })
    }

    useEffect(() => {
        getRegisteredDevices();
    }, [])

    const [devicePingLoader, setDevicePingLoader] = useState(false);
    const [timer, setTimer] = useState(0);
    //setting out fallback timer for device statuses start
    const deviceStatusFallback = () => {
        if(timer){
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            const updatedDevices = [...devicesRef.current];
            const presenceChannel = pusher.channels.channels['presence-'+masjidID];
            const members = presenceChannel.members.members;
            for (var key in updatedDevices) {
                if(members[updatedDevices[key]?.id]){
                    updatedDevices[key].device_status = 'Online';
                }else{
                    updatedDevices[key].device_status = 'Offline';
                }
            }

            //setting up new data 
            setDevicePingLoader(false);
            setDevices(updatedDevices);
            clearTimeout(newTimer);
            console.log("Devices are not responding");
        }, 60000);

        setTimer(newTimer);
    }
    //setting out fallback timer for device statuses end

    const pingForDevices = () => {
        setDevicePingLoader(true);
        if (masjidID) {
            const eventName = (masjidID + ENVIRONMENT_CONSTANT.PUSHER_DEVICE_PING_EVENT);
            const payload = {
                channel_name: masjidID,
                event_name: eventName,
                payload: {
                    masjid_id: masjidID,
                    message: 'ping'
                }
            }
            //sending api call to trigger ping event
            axios.post(SEND_PUSHER_EVENT, payload)
            .then((res) => {
                deviceStatusFallback();
            }).catch((error) => {
                console.log('Unable to ping devices.')
            });
            
            // devicePingChannel.trigger('client-' + eventName, payload);
            
        }
    }

    // useEffect(() => {
    //     deviceStatusFallback();
    // },[devices])

    //implementation for presence channel for devices start
    const [activeDevices, setActiveDevices] = useState({});
    activeDevicesRef.current = activeDevices;
    useEffect(() => {
        if(pusher){
            let subscription
            console.log(pusher)
            if(pusher.connection.state === 'connected'){
                subscription = pusher.subscribe(('presence-'+masjidID));
                
                subscription.bind("pusher:subscription_succeeded", (members) => {
                    console.log('subscription succeeded')
                });
    
                subscription.bind("pusher:member_added", (member) => {
                    const updatedData = [...devicesRef.current];
                    const index = updatedData.findIndex(it => it.id === member.id);
                    if(index >= 0){
                        updatedData[index].device_status = 'Online'
                    }

                    setDevices(updatedData);

                    // const copyMembers = {...activeDevicesRef.current};
                    // const newMember = {
                    //     id: member.id,
                    //     status: true
                    // }
                    // copyMembers = {...copyMembers, ...newMember};
                    // setActiveDevices(copyMembers);
                    console.log(subscription.members.count)
                });

                subscription.bind("pusher:member_removed", (member) => {
                    const updatedData = [...devicesRef.current];
                            const index = updatedData.findIndex(it => it.id === member.id);
                            if(index >= 0){
                                updatedData[index].device_status = 'Offline';
                            }

                    setDevices(updatedData);
                    getRegisteredDevices(member.id);
                    // const copyMembers = {...activeDevicesRef.current};
                    // delete copyMembers[member.id]
                    // setActiveDevices(copyMembers);
        
                    console.log(subscription.members.count)
                });
            }else{
                pusher.connection.bind('state_change', (state) => {
                    if(state.current === 'connected'){
                        
                        subscription = pusher.subscribe(('presence-'+masjidID));

                        subscription.bind("pusher:subscription_succeeded",  (members) => {
                            console.log('subscription succeeded');
                            const updatedData = [...devicesRef.current];
                            const presenceChannel = pusher.channels.channels['presence-' + masjidID];
                            const newMembers = presenceChannel?.members?.members;
                            for (var key in updatedData) {
                                if (newMembers[updatedData[key]?.id]) {
                                    updatedData[key].device_status = 'Online';
                                } else {
                                    updatedData[key].device_status = 'Checking';
                                }
                            }

                            setDevices(updatedData);
                        });
            
                        subscription.bind("pusher:member_added", (member) => {
                            const updatedData = [...devicesRef.current];
                            const index = updatedData.findIndex(it => it.id === member.id);
                            if(index >= 0){
                                updatedData[index].device_status = 'Online'
                            }

                            setDevices(updatedData);
                            // const copyMembers = { ...activeDevicesRef.current };
                            // const newMember = {
                            //     id: member.id,
                            //     status: true
                            // }
                            // copyMembers = { ...copyMembers, ...newMember };
                            // setActiveDevices(copyMembers);
                            console.log(subscription.members.count)
                        });
    
                        subscription.bind("pusher:member_removed", (member) => {
                            const updatedData = [...devicesRef.current];
                            const index = updatedData.findIndex(it => it.id === member.id);
                            if(index >= 0){
                                updatedData[index].device_status = 'Offline';
                            }

                            setDevices(updatedData);
                            getRegisteredDevices(member.id);
                            // const copyMembers = {...activeDevicesRef.current};
                            // delete copyMembers[member.id]
                            // setActiveDevices(copyMembers);
                
                            console.log(subscription.members.count)
                        });
                    }
                });
            }


            const newTimer = setTimeout(() => {
                
                const updatedDevices = [...devicesRef.current];
                
                for (var key in updatedDevices) {
                    if(subscription.members.members[updatedDevices[key]?.id]){
                        updatedDevices[key].device_status = 'Online';
                    }else{
                        updatedDevices[key].device_status = 'Offline';
                    }
                }
    
                //setting up new data 
                setDevicePingLoader(false);
                setDevices(updatedDevices);
                clearTimeout(newTimer);
                console.log("Devices are not responding");
            }, 60000);

            return (() => {
                if(subscription){
                    subscription.unbind_all();
                    subscription.unsubscribe();
                }
                if(newTimer){
                    clearTimeout(newTimer);
                }
            });
        }
    }, [pusher])
    //implementation for presence channel for devices start

    useEffect(() => {
        return (()  => {
            clearTimeout(timer);
        })
    }, [])

    const [privateChannel, setPrivateChannel] = useState(null);
    useEffect(() => {
        if(pusher){
            
            const privateChannelRef = pusher.subscribe('private-'+masjidID);
            setPrivateChannel(privateChannelRef);

            return(() => {
                if(privateChannelRef){
                    privateChannelRef.unsubscribe();
                    privateChannelRef.unbind_all()
                }
            })
        }
    }, [pusher]);

    useEffect(() => {

        return (() => {
            if(privateChannel){
                privateChannel.unsubscribe();
                privateChannel.unbind_all()
            }
        });
        
    }, [privateChannel])

    const refreshDeviceContent = (device) => {
        
        console.log(device);
        if(privateChannel){
            privateChannel.trigger(('client-'+device?.id+'_update'), {
                device_id: device?.id,
                message: `Refresh content for the device ${device?.device_name}`
            });
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className='row'>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                            <h5 className="text-black">Devices</h5>
                        </div>
                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                            <a onClick={(() => childRef.current.handleShow())} 
                            className="mx-3 add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3">
                                <span>
                                    <i className="fa-solid fa-circle-plus mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>Add Device</span>
                            </a>
                            <a
                            title={devicePingLoader ? 'Fetching Device Statuses' : ''}
                             onClick={(() => !devicePingLoader ? pingForDevices() : '')} 
                            className={`add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3 
                            ${devicePingLoader ? 'disabled' : ''}
                            `}>
                                <span>
                                    <i className="fa-solid fa-arrows-rotate mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>Refresh Devices Status</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row mt-3 position-relative'>
                    {
                        devices?.map((device, index) => {
                            return <div key={'device' + index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                                <div className="card" style={{minHeight:'12.3rem'}}>
                                    <div className="card-header p-3 d-flex justify-content-center">
                                        {/* <img className='image-icon' src={getImageUrl(item?.icon_image)} /> */}
                                        {/* <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg">
                                        <i className="fas fa-landmark opacity-10" aria-hidden="true"></i>                                    
                                        <i className="fa-solid fa-sliders opacity-10"></i>
                                    </div> */}
                                    </div>
                                    <div className="card-body pt-0 p-3 text-center">
                                        <Link
                                                    to={`/layout/update-device/${device.id}`}
                                                    className='cursor-pointer'>
                                                    <p className="text-center mb-0 f-16 text-bold text-black">{device.device_name} </p>
                                                </Link>
                                        <span className={`badge badge-sm mx-3 
                                        ${
                                        device?.device_status === 'Online' ? 'bg-gradient-success' :
                                        device?.device_status === 'Offline' ? 'bg-gradient-danger' :
                                          'bg-gradient-secondary'
                                            }`}>
                                            {
                                                device?.device_status === 'Online' ? 'Online' :
                                                device?.device_status === 'Offline' ? 'Offline' :
                                                'Checking'
                                            }
                                        </span>
                                        {
                                            device?.device_status === 'Offline' ?
                                                <div className='mt-2'>
                                                    <p className="mb-0 mb-0 f-xsmal text-bold">
                                                        Last Connected <span className="text-success text-sm font-weight-bolder">{
                                                            device?.last_synced_date_time ?
                                                                getFormattedDateTime(parseFloat(device?.last_synced_date_time)) : 'N/A'
                                                        }</span>
                                                    </p>
                                                </div> : ''
                                        }
                                        {/* <span className="text-xs">Belong Interactive</span> */}
                                        <hr className=
                                        {`horizontal dark my-3 ${device?.device_status === 'Online' ? 'mt-5' : ''}`} />
                                        <div>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <Link
                                                    to={`/layout/update-device/${device.id}`}
                                                    className='me-2 cursor-pointer' title='Edit Device'>
                                                    <i className="fa-regular fa-pen-to-square f-16 card-actions"></i>
                                                </Link>

                                                <div title='Delete Device' onClick={() => {
                                                    confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                    confirmationDialogRef.current.setMessage(`Are you sure you want to delete ${device.device_name}?`);
                                                    confirmationDialogRef.current.setButtonLabel("Delete Device");
                                                    confirmationDialogRef.current.openDialog(device);
                                                }} className='cursor-pointer delete-icon me-2'>
                                                    <i className="fa-solid fa-trash f-16 card-actions"></i>
                                                </div>
                                                <div onClick={() => refreshDeviceContent(device)} title='Refresh Device' className='cursor-pointer delete-icon'>
                                                    <i className="fa-solid fa-rotate f-16 card-actions"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        })
                    }

                    {
                        dataLoader ? 
                        <Loader /> : 
                        !dataLoader && devices?.length === 0 ?
                        <div className='text-center mt-3'>
                            <i style={{ fontSize: '4rem' }} className="fa-solid fa-list-ul"></i>
                            <p>No Devices Found</p>
                        </div>
                        : ''}
                </div>
            </div>
            <AddDeviceModal ref={childRef} getRegisteredDevices={getRegisteredDevices} />

            {/* confirmation dialog start */}
            <ConfirmationDialog
            ref={confirmationDialogRef}
            executeAction={deleteDevice}
             />
            {/* confirmation dialog end */}
        </>
    );
});

export default Devices;