import Pusher from 'pusher-js';
import { ENVIRONMENT_CONSTANT } from "../constants/env.constant";
import { getUserInfo } from '../utils/user-info';

const masjidID = getUserInfo()?.masjid_id;

//attaching cookie for authorization in pusher channel authentication API
Pusher.Runtime.createXHR = () => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
}

const pusher = new Pusher(ENVIRONMENT_CONSTANT.PUSHER_KEY, {
    cluster: 'us2',
    channelAuthorization:{
        endpoint:ENVIRONMENT_CONSTANT.API_ENDPOINT + "pusher/auth",
        headers:{'masjidid': masjidID}
    }
});

export default pusher;